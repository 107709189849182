<template>
  <v-row>
    <v-col class="mb-4">
      <order-dialog v-if="openDialog" v-model='openDialog' :orderNumber='orderNumber' @close="closeDialog" />
          <h2 class="font-weight-bold mx-1 mb-3">Recent Orders</h2>
          <v-data-table
            :items="orders.v2Orders"
            item-key="orderNumber"
            :headers="headers"
            :single-expand=true
            :expanded.sync="expanded1"
            hide-default-footer
            :disable-pagination=true
            show-expand
          >
              <template v-slot:[`item.numProducts`]="{ item }">
                {{ item.orderItems.length }}
              </template>
              <template v-slot:[`item.total`]="{ item }">
              {{ `£${item.total.toFixed(2)}` }}
            </template>
            <template v-slot:[`item.orderDate`]="{ item }">
              {{ new Date(item.orderDate).toLocaleDateString() }}
            </template>
            <!-- <template v-slot:[`item.copyToCart`]="{ item }">
              <v-btn small color="primary" @click="copy(item.orderItems)" >
                Copy to cart
              </v-btn>
            </template> -->
            <template v-slot:[`item.download`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="download(item.orderNumber)"
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-download
                  </v-icon>
                </template>
                <span>Download PDF</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.copy`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="copy(item.orderItems)"
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copy all items to current order</span>
              </v-tooltip>
            </template>
            <!-- <template v-slot:expanded-item-icon="{}">
                <v-icon>check</v-icon>
            </template> -->
            <template v-slot:expanded-item="{ headers, item }">
              <td class="py-2" :colspan="headers.length">
                <v-data-table
                  :items="item.orderItems"
                  hide-default-footer
                  :sort-by="['product.category','product.title']"
                  :disable-pagination=true
                  :headers="orderItemHeaders"
                >
                  <template v-slot:[`item.unitPrice`]="{ item }">
                      {{ `£${item.unitPrice.toFixed(2)}` }}
                  </template>
                  <template v-slot:[`item.img`]="{ item }">
                    <image-thumbnail
                      class="my-2"
                      :image="item.product.productImage"
                      :maxSize="50"
                      tooltip="true">
                    </image-thumbnail>
                    <!-- <v-img class="mx-2" max-height="50px" max-width="50px"
                        :src="`${imageUrl}/100px/${item.product.images[0]}`"
                      ></v-img> -->
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
          <h2 class="font-weight-bold mx-1 mb-3">Older Orders</h2>

          <v-data-table
            :items="orders.v1Orders"
            item-key="orderNumber"
            :headers="headers"
            :single-expand=true
            :expanded.sync="expanded2"
            hide-default-footer
            :disable-pagination=true
            show-expand
          >
              <template v-slot:[`item.numProducts`]="{ item }">
                {{ item.orderItems.length }}
              </template>
              <template v-slot:[`item.total`]="{ item }">
              {{ `£${item.total.toFixed(2)}` }}
            </template>
            <template v-slot:[`item.orderDate`]="{ item }">
              {{ new Date(item.orderDate).toLocaleDateString() }}
            </template>
            <template v-slot:[`item.copyToCart`]="{ item }">
              <v-btn small color="primary" @click="copy(item.orderItems)" >
                Copy to cart
              </v-btn>
            </template>
            <template v-slot:[`item.download`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="download(item.orderNumber)"
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-download
                  </v-icon>
                </template>
                <span>Download PDF</span>
              </v-tooltip>
            </template>
            <!-- <template v-slot:[`item.copy`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="copy(item.orderItems)"
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copy all items to current order</span>
              </v-tooltip>
            </template> -->
            <!-- <template v-slot:expanded-item-icon="{}">
                <v-icon>check</v-icon>
            </template> -->
            <template v-slot:expanded-item="{ headers, item }">
              <td class="py-2" :colspan="headers.length">
                <v-data-table
                  :items="item.orderItems"
                  hide-default-footer
                  :sort-by="['product.category','product.title']"
                  :disable-pagination=true
                  :headers="orderItemHeaders"
                >
                  <template v-slot:[`item.unitPrice`]="{ item }">
                      {{ `£${item.unitPrice.toFixed(2)}` }}
                  </template>
                  <template v-slot:[`item.img`]="{ item }">
                    <image-thumbnail
                      class="my-2"
                      :image="item.product.productImage"
                      :maxSize="50"
                      tooltip="true">
                    </image-thumbnail>
                    <!-- <v-img class="mx-2" max-height="50px" max-width="50px"
                        :src="`${imageUrl}/100px/${item.product.images[0]}`"
                      ></v-img> -->
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        <confirm-dialog ref="confirmDialog" />
    </v-col>
  </v-row>
</template>

<script>
import OrderApi from '@/api/order'
import OrderDialog from '@/components/OrderDialog'
import ImageThumbnail from '@/components/ImageThumbnail.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  components: {
    OrderDialog,
    ImageThumbnail,
    ConfirmDialog
  },
  data () {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      orderNumber: null,
      openDialog: false,
      expanded1: [],
      expanded2: [],
      orders: [],
      headers: [
        {
          text: 'Order Id',
          align: 'start',
          value: 'orderNumber'
        },
        {
          text: '# products',
          align: 'end',
          value: 'numProducts'
        },
        {
          text: '# Items',
          align: 'end',
          value: 'numItems'
        },
        {
          text: 'Total',
          align: 'end',
          value: 'total'
        },
        {
          text: 'Date',
          value: 'orderDate'
        },
        {
          text: 'Status',
          align: 'start',
          value: 'status'
        },
        {
          text: '',
          align: 'end',
          value: 'copyToCart'
        },
        {
          text: '',
          align: 'end',
          value: 'download'
        }
      ],
      orderItemHeaders: [
        { text: '', value: 'img', width: '100px' },
        { text: 'Quantiy', value: 'qty' },
        { text: 'Unit Price', value: 'unitPrice' },
        { text: 'Category', value: 'product.category' },
        {
          text: 'Title',
          align: 'start',
          value: 'product.title'
        },
        { text: 'Variant', value: 'product.imageColour' }
      ]
    }
  },
  methods: {
    async download (orderNumber) {
      await OrderApi.getUserPDF(orderNumber)
    },
    async copy (orderItems) {
      if (
        await this.$refs.confirmDialog.open(
          'Copy this orders items to your shopping cart?',
          `If your cart already contains items in this order, the quantities will be replaced by the quantity in the order`,
        )
      ) {
        this.$store.dispatch('cart/copyOrderItems', orderItems)
      }
    },
    showOrder (orderNumber) {
      this.openDialog = true
      this.orderNumber = orderNumber
    },
    closeDialog () {
      this.openDialog = false
    }
  },
  async created () {
    await OrderApi.countUserOrderItems().then((results) => { console.log('done') })
    OrderApi.getUserOrders().then((results) => { this.orders = results.data })
  }
}
</script>
